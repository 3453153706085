export default {
  firebase: {
    apiKey: 'AIzaSyDTY7Pfp3LODdmg43cLXZwOP6uq01kMbZc',
    authDomain: 'wattpark-firebase-staging.firebaseapp.com',
    databaseURL: 'https://wattpark-firebase-staging.firebaseio.com',
    projectId: 'wattpark-firebase-staging',
    storageBucket: 'wattpark-firebase-staging.appspot.com',
    messagingSenderId: '617720399604'
  },
  baseUrl: 'https://wattpark-api.ikomobi.fr'
};
